//
//
//
//
//
//

import Login from '../components/Login.vue'
export default {
  layout: 'login',
  components: {
    Login
  },
  data() {
    return {
      resetPasswordPrompt: false,
    }
  }
}
