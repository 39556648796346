







































































import axios from 'axios'

export default {
  name: 'Login',
  // fetch() {
  //   // INFO -> this.$fire.auth user etc. are accessible
  //   // INFO -> this.$store.state.authUser is accessible even on server-side
  // },
  data: () => ({
    formData: {
      email: '',
      password: '',
    },
    buttonLoading: false,
    loading: true,
    success: false,
  }),
  props: ['resetPassword'],
  methods: {
    continueToHome() {
      this.buttonLoading = true
      this.$router.push('/');
    },
    async createUser() {
      try {
        await this.$fire.auth.createUserWithEmailAndPassword(
          this.formData.email,
          this.formData.password
        )
      } catch (e) {
        alert(e)
      }
    },
    async updatePassword() {
      this.buttonLoading = true
      try {
        await this.$fire.auth.currentUser.updatePassword(
          this.formData.password
        )
        await axios.post(
          "/api/activate-user",
          {
            uid: this.$fire.auth.currentUser.uid
          }).then((response) => {
            this.buttonLoading = false
            this.success = true;
          });
      } catch (e) {
        this.buttonLoading = false
        alert(e)
      }
    },
    async signInUser() {
      this.buttonLoading = true
      try {
        await this.$fire.auth.signInWithEmailAndPassword(
          this.formData.email,
          this.formData.password
        )
        try {
          await axios.post(
            "/api/user",
            {
              uid: this.$fire.auth.currentUser.uid
            }).then((res) => {
              const activated = res.data.data.activated
              if(activated) {
                this.storeName = res.data.data.storeName
                this.storeDomain = res.data.storeDomain
                this.resetPassword = false
                this.buttonLoading = false
                this.$router.push('/');
              } else {
                this.resetPassword = true
                this.buttonLoading = false
              }
            });
        } catch (e) {
          this.buttonLoading = false
          console.log(e);
        }
      } catch (e) {
        alert(e)
      }
    },
  },
  mounted() {
    this.loading = false;
  }
}
